import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useEffect } from 'react'

function Contact() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className="container-xxl bg-white p-0">
            <div className="container-xxl position-relative p-0">
                <Navbar ActiveTab={5} />
                <div class="container-xxl py-5 bg-primary hero-header">
                    <div class="container my-5 py-5 px-lg-5">
                        <div class="row g-5 py-5">
                            <div class="col-12 text-center">
                                <h1 class="text-white animated slideInDown">Contact</h1>
                                <hr class="bg-white mx-auto mt-0" style={{ width: "90px" }} />
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb justify-content-center">
                                        <li class="breadcrumb-item">
                                            <a class="text-white" href="#">Home</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a class="text-white" href="#">Pages</a>
                                        </li>
                                        <li
                                            class="breadcrumb-item text-white active"
                                            aria-current="page"
                                        >
                                            Contact
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container py-5 px-lg-5">
                    <div class="wow fadeInUp" data-wow-delay="0.1s">
                        <p class="section-title text-secondary justify-content-center">
                            <span></span>Contact Us<span></span>
                        </p>
                        <h1 class="text-center mb-5">Contact For Any Query</h1>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-7">
                            <div class="wow fadeInUp" data-wow-delay="0.3s">
                                <form>
                                    <div class="row g-3">
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="name"
                                                    placeholder="Your Name"
                                                />
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input
                                                    type="email"
                                                    class="form-control"
                                                    id="email"
                                                    placeholder="Your Email"
                                                />
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="subject"
                                                    placeholder="Subject"
                                                />
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <textarea
                                                    class="form-control"
                                                    placeholder="Leave a message here"
                                                    id="message"
                                                    style={{ height: "150px" }}
                                                ></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-primary w-100 py-3" type="submit">
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact